import { useContext, useState } from 'react'
import styles from './AppHeader.module.scss'
import LabelButton from '../LabelButton/LabelButton'
import WalletButton from '../WalletButton/WalletButton'
import { Web3Context } from '../../context'
import { LANDING_LINK } from '../../constants/constants'

interface IProps {
  onConnectWallet: (a: string) => void
}
export const AppHeader = ({ onConnectWallet }: IProps) => {
  const {
    isConnected,
    user,
    currentNetwork,
    isNetworkPending,
    openConnectModal,
    setOpenConnectModal,
    pendingCount,
  } = useContext(Web3Context)

  const [isOpenTransactions, setIsOpenTransactions] = useState<boolean>(false)
  const [lastClickedButton, setLastClickedButton] = useState('')

  const openTransactions = (e: any) => {
    const id = e.currentTarget.id

    if (lastClickedButton !== id) {
      setTimeout(() => {
        setIsOpenTransactions(true)
        setLastClickedButton(id)
      }, 100)
    }
  }

  const closeTransactions = () => {
    setTimeout(() => {
      setIsOpenTransactions(false)
      setLastClickedButton('')
    }, 0)
  }

  return (
    <header className={styles.header}>
      <a
        className={styles.link}
        href={LANDING_LINK}
        target="_blank"
        rel="noreferrer"
      >
        <span className={styles.logo}></span>
      </a>
      <div className={styles.labels}>
        {pendingCount ? (
          <LabelButton
            textValue={pendingCount.toString() || ''}
            textName="Pending"
            onButtonClick={openTransactions}
          />
        ) : null}
        <WalletButton
          setIsOpenModal={setOpenConnectModal}
          isOpenModal={openConnectModal}
          currentNetwork={currentNetwork}
          text={user?.addressShort || ''}
          isConnected={isConnected}
          isPending={isNetworkPending}
          isOpen={isOpenTransactions}
          onButtonClick={onConnectWallet}
          onConnectedButtonClick={openTransactions}
          onCloseTransactions={closeTransactions}
        />
      </div>
    </header>
  )
}
