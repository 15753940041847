import {
  ARBITRUM_BRIDGE_CONTRACT_ADDRESS,
  ARBITRUM_CHAIN_ID_HEX,
  MOONBEAM_BRIDGE_CONTRACT_ADDRESS,
  MOONBEAM_CHAIN_ID,
  MOONBEAM_CHAIN_ID_HEX,
  MOONRIVER_BRIDGE_CONTRACT_ADDRESS,
  MOONRIVER_CHAIN_ID,
  MOONRIVER_CHAIN_ID_HEX,
  NETWORK_CHAINS,
  NETWORKS_ENUM,
  ORB_ARBITRUM_TOKEN,
  ORB_MOONBEAM_TOKEN,
  ORB_MOONRIVER_TOKEN,
  ORB_ZKSYNC_TOKEN,
  ZKSYNC_BRIDGE_CONTRACT_ADDRESS,
  ZKSYNC_CHAIN_ID,
  ZKSYNC_CHAIN_ID_HEX,
} from '../constants/constants'

import MoonriverBridgeAbi from '../contracts/MoonbaseAlphaBridge.json'
import MoonbeamBridgeAbi from '../contracts/GoerliBridge.json'

import chains from '../assets/data/chains.json'

export function formatNumber(value: number) {
  const formatters = Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  return formatters.format(value)
}

export async function copyText(text: string): Promise<boolean> {
  if (!navigator.clipboard) {
    return false
  }

  return new Promise((resolve, reject) => {
    return navigator.clipboard.writeText(text).then(
      function () {
        resolve(true)
      },
      function () {
        reject(false)
      }
    )
  })
}

export const getNetworkName = (chainId: string): NETWORKS_ENUM | null => {
  if (chainId.toLowerCase() === MOONBEAM_CHAIN_ID_HEX.toLowerCase())
    return NETWORKS_ENUM.MOONBEAM
  if (chainId.toLowerCase() === MOONRIVER_CHAIN_ID_HEX.toLowerCase())
    return NETWORKS_ENUM.MOONRIVER
  if (chainId.toLowerCase() === ARBITRUM_CHAIN_ID_HEX.toLowerCase())
    return NETWORKS_ENUM.ARBITRUM
  if (chainId.toLowerCase() === ZKSYNC_CHAIN_ID_HEX.toLowerCase())
    return NETWORKS_ENUM.ZKSYNC

  return null
}

export const getChainId = (network: NETWORKS_ENUM) => {
  if (network === NETWORKS_ENUM.MOONRIVER) return MOONRIVER_CHAIN_ID_HEX
  if (network === NETWORKS_ENUM.MOONBEAM) return MOONBEAM_CHAIN_ID_HEX
  if (network === NETWORKS_ENUM.ARBITRUM) return ARBITRUM_CHAIN_ID_HEX
  if (network === NETWORKS_ENUM.ZKSYNC) return ZKSYNC_CHAIN_ID_HEX

  return null
}

export const getChainIdNumber = (network: NETWORKS_ENUM | null) => {
  if (!network) return ''
  return NETWORK_CHAINS[network]
}

export const getNetworknNameById = (chainId: number | null) => {
  if (!chainId) return ''
  return chains?.find((chain) => chain?.chainId === chainId)?.name || ''
}

export const getOrbToken = (network: NETWORKS_ENUM | null): string | null => {
  if (network === NETWORKS_ENUM.MOONRIVER) {
    return ORB_MOONRIVER_TOKEN
  }

  if (network === NETWORKS_ENUM.MOONBEAM) {
    return ORB_MOONBEAM_TOKEN
  }

  if (network === NETWORKS_ENUM.ARBITRUM) {
    return ORB_ARBITRUM_TOKEN
  }

  if (network === NETWORKS_ENUM.ZKSYNC) {
    return ORB_ZKSYNC_TOKEN
  }

  return null
}

export const getBridgeToken = (
  network: NETWORKS_ENUM | null
): string | null => {
  if (network === NETWORKS_ENUM.MOONRIVER)
    return MOONRIVER_BRIDGE_CONTRACT_ADDRESS
  if (network === NETWORKS_ENUM.MOONBEAM)
    return MOONBEAM_BRIDGE_CONTRACT_ADDRESS
  if (network === NETWORKS_ENUM.ARBITRUM)
    return ARBITRUM_BRIDGE_CONTRACT_ADDRESS
  if (network === NETWORKS_ENUM.ZKSYNC) return ZKSYNC_BRIDGE_CONTRACT_ADDRESS

  return null
}

export const getBridgeAbi = (network: NETWORKS_ENUM | null): any => {
  if (network === NETWORKS_ENUM.MOONRIVER) return MoonriverBridgeAbi
  if (network === NETWORKS_ENUM.MOONBEAM) return MoonbeamBridgeAbi
  if (network === NETWORKS_ENUM.ARBITRUM) return MoonbeamBridgeAbi
  if (network === NETWORKS_ENUM.ZKSYNC) return MoonbeamBridgeAbi

  return null
}

export function commify(value: string) {
  const splitedValue = value.split('.')
  let parts2 = ''
  const parts = splitedValue[0].replace(/[\s,]/g, '')

  const thousands = /\B(?=(\d{3})+(?!\d))/g
  if (splitedValue.length > 1) {
    parts2 = '.' + splitedValue[1]
  }

  return parts.replace(thousands, ',') + parts2
}

export const mergeByProperty = (target: any[], source: any[], prop: string) => {
  source.forEach((sourceElement) => {
    let targetElement = target.find((targetElement) => {
      return sourceElement[prop] === targetElement[prop]
    })
    targetElement
      ? Object.assign(targetElement, sourceElement)
      : target.push(sourceElement)
  })

  return target
}
