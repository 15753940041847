import styles from './InfoButton.module.scss'
import NetworkIcon from '../NetworkIcon/NetworkIcon'

interface InfoButtonProps {
  text: string
  direction: string
  chainId: string
}

function InfoButton({ direction, text, chainId }: InfoButtonProps) {
  return (
    <button className={styles.button}>
      <em>{direction}</em>
      <div className={styles.buttonContent}>
        <NetworkIcon chainId={chainId} size={20} />
        <span title={text}>{text}</span>
      </div>
    </button>
  )
}

export default InfoButton
