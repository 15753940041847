import React from 'react'
import styles from './Pending.module.scss'
import PendingImg from '../../assets/img/app/pending.png'

function Pending() {
  return (
    <div className={styles.pendingHolder}>
      <img src={PendingImg} alt="Pending" />
      <p>Pending confirmation on wallet</p>
    </div>
  )
}

export default Pending
