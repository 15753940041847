import React, { useContext } from 'react'
import { NETWORKS_ENUM, SYSTEM_FEE_PERCENT } from '../../constants/constants'
import { Web3Context } from '../../context'
import { ITransactionEstimate } from '../../types/transaction.interface'

import { ReactComponent as IconOrb } from '../../assets/icons/orb-icn.svg'

import styles from './Receive.module.scss'
import { NumericFormat } from 'react-number-format'
import NetworkIcon from '../NetworkIcon/NetworkIcon'
import { getChainIdNumber } from '../../utils/utils'

interface ReceiveTokenProps {
  data: ITransactionEstimate | null
  fromNetwork: NETWORKS_ENUM | null
}

function Receive({ data = null, fromNetwork = null }: ReceiveTokenProps) {
  const { user } = useContext(Web3Context)
  const isMobile = window.innerWidth < 400

  // const formatBalance = () => {
  //   if (data) {
  //     const comasCount = Math.floor(
  //       (data.receive.toString().split('.')[0].length - 1) / 3
  //     )
  //     const sliceValue = 16 - comasCount

  //     return data.receive.toString().slice(0, sliceValue)
  //   }
  // }
  return (
    <>
      <div className={styles.receive}>
        {data?.receive ? (
          <div
            className={`${styles.receive__row} ${styles.receive__totalContainer}`}
          >
            <div className={styles.receive__mainLabel}>You will receive</div>
            <div className={styles.receive__total}>
              <IconOrb />{' '}
              <NumericFormat
                value={data.receive}
                displayType="text"
                suffix={isMobile ? '...' : ''}
                decimalSeparator="."
                thousandSeparator=","
                allowLeadingZeros={false}
                valueIsNumericString
                decimalScale={isMobile ? 8 : 18}
              />{' '}
              ORB
            </div>
          </div>
        ) : null}

        <div className={styles.receive__row}>
          <div className={styles.receive__label}>System fee</div>
          <div className={styles.receive__value}>{SYSTEM_FEE_PERCENT}% </div>
        </div>
        {data?.networkFee ? (
          <div className={styles.receive__row}>
            <div className={styles.receive__label}>Network fee</div>
            <div className={styles.receive__value}>
              <NetworkIcon
                chainId={getChainIdNumber(fromNetwork) || ''}
                size={18}
              />
              <NumericFormat
                value={data?.networkFee}
                displayType="text"
                decimalSeparator="."
                thousandSeparator=","
                decimalScale={8}
                fixedDecimalScale
                allowLeadingZeros
              />
              {user?.symbol || null}{' '}
            </div>
          </div>
        ) : null}
        {/*<div className={styles.receive__row}>*/}
        {/*  <div className={styles.receive__label}>Mint fee</div>*/}
        {/*  <div className={styles.receive__value}>*/}
        {/*    <IconOrb />{' '}*/}
        {/*    <NumericFormat*/}
        {/*        value={data?.networkFee}*/}
        {/*        displayType="text"*/}
        {/*        decimalSeparator="."*/}
        {/*        thousandSeparator=","*/}
        {/*        decimalScale={8}*/}
        {/*        fixedDecimalScale*/}
        {/*        allowLeadingZeros*/}
        {/*    />*/}
        {/*    ORB*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </>
  )
}

export default Receive
