import React, { useContext } from 'react'
import styles from '../NetworkModal/NetworkModal.module.scss'
import closeIcon from '../../assets/icons/close-icn.svg'
import { NETWORKS, NETWORKS_ENUM } from '../../constants/constants'
import { getChainIdNumber, getNetworknNameById } from '../../utils/utils'
import NetworkIcon from '../NetworkIcon/NetworkIcon'
import { Web3Context } from '../../context'

interface Props {
  openNetworkModal: {
    open: boolean
    isSource: boolean
  }
  setOpenNetworkModal: (a: { open: boolean; isSource: boolean }) => void
  setFromNetwork: (a: NETWORKS_ENUM) => void
  setToNetwork: (a: NETWORKS_ENUM) => void
  toNetwork: NETWORKS_ENUM
  fromNetwork: NETWORKS_ENUM
}
const NetworkModal: React.FC<Props> = ({
  setOpenNetworkModal,
  openNetworkModal,
  setFromNetwork,
  setToNetwork,
  fromNetwork,
  toNetwork,
}) => {
  const handleClose = () => {
    setOpenNetworkModal({
      open: false,
      isSource: false,
    })
  }

  const { switchNetwork } = useContext(Web3Context)

  const handleChangeNetwork = (network: NETWORKS_ENUM) => {
    openNetworkModal.isSource ? setFromNetwork(network) : setToNetwork(network)
    openNetworkModal.isSource && switchNetwork(network)
    handleClose()
  }

  const readerNetworks = () => {
    const networksForDemo = NETWORKS.filter(
      (el) => NETWORKS_ENUM[el] !== NETWORKS_ENUM.ARBITRUM
    )
    const networksToFilter =
      process.env.REACT_APP_BRIDGE_LANDING_LINK ===
      'https://demo.orionbridge.xyz'
        ? networksForDemo
        : NETWORKS
    const sourceNetworks = networksToFilter.filter(
      (el) => NETWORKS_ENUM[el] !== toNetwork
    )
    const destinationNetworks = networksToFilter.filter(
      (el) => NETWORKS_ENUM[el] !== fromNetwork
    )
    const arrToMap = openNetworkModal.isSource
      ? sourceNetworks
      : destinationNetworks

    return arrToMap.map((el, index) => {
      return (
        <div
          key={index}
          onClick={() => handleChangeNetwork(NETWORKS_ENUM[el])}
          className={styles.networkCard}
        >
          <div className={styles.imgWrapper}>
            <NetworkIcon chainId={getChainIdNumber(NETWORKS_ENUM[el]) || ''} />
          </div>
          <div className={styles.networkName}>
            {getNetworknNameById(+getChainIdNumber(NETWORKS_ENUM[el]))}
          </div>
        </div>
      )
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>
          {' '}
          {openNetworkModal.isSource ? 'Source Network' : 'Destination Network'}
        </h1>
        <img
          onClick={() => {
            handleClose()
          }}
          src={closeIcon}
        />
      </div>
      <div className={styles.subTitle}>Network name</div>
      {readerNetworks()}
    </div>
  )
}

export default NetworkModal
