import AppLoader from '../AppLoader/AppLoader'
import styles from './Button.module.scss'

interface ButtonTokenProps {
  textBtn: string
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  pending?: boolean
  onClick?: () => void
}

function Button({
  textBtn,
  className,
  disabled = false,
  onClick,
  type = 'button',
  pending = false,
}: ButtonTokenProps) {
  const buttonClass = className
    ? `${styles.button} ${className}`
    : styles.button

  return (
    <div className={styles.btnHolder}>
      <button
        className={buttonClass}
        disabled={disabled}
        type={type}
        onClick={onClick}
      >
        <div className={styles.btnContent}>
          {pending ? <AppLoader /> : null}
          {textBtn}
        </div>
      </button>
    </div>
  )
}

export default Button
