import styles from './SelectToken.module.scss'
import NetworkIcon from '../NetworkIcon/NetworkIcon'
import arrow from '../../assets/icons/arrow-select-icn.svg'

import { getNetworknNameById } from '../../utils/utils'

interface SelectTokenProps {
  chainId: string
  setOpenNetworkModal: () => void
}

function SelectToken({ chainId, setOpenNetworkModal }: SelectTokenProps) {
  return (
    <>
      <div onClick={setOpenNetworkModal} className={styles.selectHolder}>
        {' '}
        <NetworkIcon chainId={chainId} size={20} />
        <input
          className={styles.selectToken}
          value={getNetworknNameById(+chainId)}
          readOnly
        />
        <img className={styles.arrow} src={arrow} />
        <label className={styles.inputLabel}>Network</label>
      </div>
    </>
  )
}

export default SelectToken
