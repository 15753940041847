import styles from './TransactionResult.module.scss'
import Button from '../Button/Button'
import NetworkName from '../NetworkName/NetworkName'
import { NETWORKS_ENUM } from '../../constants/constants'
import { ITransactionEstimate } from '../../types/transaction.interface'
import { NumericFormat } from 'react-number-format'
import { getChainIdNumber } from '../../utils/utils'

interface IProps {
  fromNetwork: NETWORKS_ENUM
  toNetwork: NETWORKS_ENUM
  onButtonClick: () => void
  transactionEstimate: ITransactionEstimate | null
}

function TransactionResult({
  fromNetwork,
  toNetwork,
  onButtonClick,
  transactionEstimate = null,
}: IProps) {
  return (
    <div className={styles.transactionResultHolder}>
      <div>
        <div className={styles.transactionHeader}>
          <h1> Transaction Submitted</h1>
        </div>
        <div className={styles.transactionStats}>
          <NetworkName
            chainId={getChainIdNumber(fromNetwork) || ''}
            showName={false}
          />
          <div className={styles.durationAmount}>
            <span className={styles.duration}>Please wait a moment</span>
            <span className={styles.amount}>
              <NumericFormat
                value={transactionEstimate?.receive || ''}
                displayType="text"
                decimalSeparator="."
                thousandSeparator=","
                allowLeadingZeros
                valueIsNumericString
                decimalScale={4}
                fixedDecimalScale
              />{' '}
              ORB
            </span>
          </div>
          <NetworkName
            chainId={getChainIdNumber(toNetwork) || ''}
            showName={false}
          />
        </div>
        <p className={styles.resultText}>
          Your assets are on the way.
          <br />
          We wiil notify you once it’s done
        </p>
      </div>
      <Button
        className={styles.button}
        textBtn="Got It"
        onClick={onButtonClick}
      />
    </div>
  )
}

export default TransactionResult
