import styles from './AppCheckbox.module.scss'

import { ReactComponent as IconCheckbox } from '../../assets/icons/checkbox.svg'
import { ReactComponent as IconCheckboxChecked } from '../../assets/icons/checkbox-checked.svg'

interface IProps {
  label: string
  isChecked: boolean
  name: string
  disabled?: boolean
  onChange: (value: boolean) => void
}

export const AppCheckbox = ({
  label = '',
  isChecked = false,
  name = '',
  onChange,
  disabled = false,
}: IProps) => {
  return (
    <div
      className={`${styles.checkbox} ${
        disabled ? styles.checkboxDisabled : null
      }`}
      onClick={() => onChange(!isChecked)}
    >
      {!isChecked ? (
        <IconCheckbox className={styles.checkboxIcon} />
      ) : (
        <IconCheckboxChecked className={styles.checkboxIcon} />
      )}
      {label}
    </div>
  )
}
