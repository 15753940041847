import { ReactComponent as PendingIcon } from '../../assets/icons/pending-icon.svg'

import styles from './AppLoader.module.scss'

const AppLoader = () => {
  return (
    <div className={styles.loader}>
      <PendingIcon className={styles.loader__icon} />
    </div>
  )
}

export default AppLoader
