import InfoButton from '../InfoButton/InfoButton'
import Pending from '../Pending/Pending'
import Receive from '../Receive/Receive'

import styles from './TransactionPending.module.scss'
import { NETWORKS_ENUM } from '../../constants/constants'
import { ITransactionEstimate } from '../../types/transaction.interface'
import { getChainIdNumber, getNetworknNameById } from '../../utils/utils'

interface IProps {
  fromNetwork: NETWORKS_ENUM
  toNetwork: NETWORKS_ENUM
  transactionEstimate: ITransactionEstimate | null
}

export const TransactionPending = ({
  fromNetwork,
  toNetwork,
  transactionEstimate = null,
}: IProps) => {
  return (
    <div>
      <Pending />
      <div className={styles.infoBtnRow}>
        <InfoButton
          direction={'From'}
          chainId={getChainIdNumber(fromNetwork) || ''}
          text={getNetworknNameById(+getChainIdNumber(fromNetwork) || null)}
        />
        <InfoButton
          direction={'To'}
          chainId={getChainIdNumber(toNetwork) || ''}
          text={getNetworknNameById(+getChainIdNumber(toNetwork) || null)}
        />
      </div>
      <Receive data={transactionEstimate} fromNetwork={fromNetwork} />
    </div>
  )
}
