import TransactionResult from '../Transaction/TransactionResult'
import { TransactionForm } from '../Transaction/TransactionForm'
import { TransactionPending } from '../Transaction/TransactionPending'
import { TransactionSourceNetwork } from '../Transaction/TransactionSourceNetwork'
import { useContext, useEffect, useState } from 'react'
import { ReactComponent as IconArrow } from '../../assets/icons/arrow.svg'

import styles from './AppContent.module.scss'
import {
  ETransactionStatus,
  LOCAL_TRANSACTIONS_LS_KEY,
  NETWORKS_ENUM,
  SYSTEM_FEE_PERCENT,
} from '../../constants/constants'
import { Web3Context } from '../../context'

import OrbAbi from '../../contracts/OrbABI.json'
import { getChainId, getNetworknNameById } from '../../utils/utils'
import {
  IHistory,
  ITransactionEstimate,
} from '../../types/transaction.interface'
import { toastError, toastSuccess } from '../../utils/toast'
import { toBn } from 'evm-bn'
import { IBridgeReceipt } from '../../types/bridge.interface'
import NetworkModal from '../NetworkModal/NetworkModal'

enum TRANSACTION_STEPS {
  CREATE_TRANSACTION = 'createTransaction',
  PENDING_TRANSACTION = 'pendingTransaction',
  SUBMITTED_TRANSACTION = 'submittedTransaction',
  SOURCE_NETWORK = 'sourceNetwork',
}

const AppContent = () => {
  const [currrentStep, setCurrentStep] = useState<TRANSACTION_STEPS>(
    TRANSACTION_STEPS.CREATE_TRANSACTION
  )
  const [fromNetwork, setFromNetwork] = useState<NETWORKS_ENUM>(
    process.env.REACT_APP_BRIDGE_LANDING_LINK === 'https://demo.orionbridge.xyz'
      ? NETWORKS_ENUM.MOONRIVER
      : NETWORKS_ENUM.MOONBEAM
  )
  const [toNetwork, setToNetwork] = useState<NETWORKS_ENUM>(
    process.env.REACT_APP_BRIDGE_LANDING_LINK === 'https://demo.orionbridge.xyz'
      ? NETWORKS_ENUM.MOONBEAM
      : NETWORKS_ENUM.ARBITRUM
  )

  const [totalAmount, setTotalAmount] = useState<string>('0')
  const [address, setAddress] = useState<string>('')
  const [isAddressError, setIsAddressError] = useState<boolean>(false)
  const [transactionEstimate, setTransactionEstimate] =
    useState<ITransactionEstimate>({
      receive: 0,
      systemFee: 0,
      networkFee: 0,
      fullAmount: 0,
    })

  const [isApprovePending, setIsApprovePending] = useState<boolean>(false)
  const [neededGas, setNeededGas] = useState(0)
  const [isApproveNeed, setIsApproveNeed] = useState<boolean>(false)
  const [openNetworkModal, setOpenNetworkModal] = useState({
    open: false,
    isSource: false,
  })

  const [savedToNetwork, setSavedToNetwork] = useState<NETWORKS_ENUM | null>(
    null
  )

  const infinity =
    '115792089237316195423570985008687907853269984665640564039457.584007913129639935'

  const {
    isConnected,
    user,
    currentNetwork,
    web3,
    balance,
    switchNetwork,
    getWalletData,
    isNetworkPending,
    orbToken,
    bridgeAbi,
    bridgeToken,
    getTokenBalance,
    addLocalTransaction,
    fetchHistory,
    localPendingCount,
    setOpenConnectModal,
    isNetworkError,
  } = useContext(Web3Context)

  const swapNetworks = () => {
    setSavedToNetwork(toNetwork)
    switchNetwork(toNetwork)

    setFromNetwork((prev) => {
      setToNetwork(prev)
      return toNetwork
    })
  }

  useEffect(() => {
    if (isNetworkError) {
      if (currentNetwork) {
        setFromNetwork(currentNetwork as NETWORKS_ENUM)
      } else {
        setFromNetwork(toNetwork)
      }

      if (savedToNetwork) {
        setToNetwork(savedToNetwork as NETWORKS_ENUM)
        setSavedToNetwork(null)
      }
    }
  }, [isNetworkError])

  useEffect(() => {
    if (!balance) {
      setTotalAmount('0')
    }
  }, [currentNetwork])

  useEffect(() => {
    if (!balance) {
      setTotalAmount('0')
    }
  }, [user.address])

  useEffect(() => {
    checkIsApproveNeed()
  }, [user.allowance, totalAmount, currentNetwork])

  const checkIsApproveNeed = () => {
    const allowance = +user?.allowance || 0
    const amount = +totalAmount

    if (!allowance) {
      setIsApproveNeed(true)
      return
    } else if (!amount) {
      setIsApproveNeed(false)
      return
    } else if (allowance < amount) {
      setIsApproveNeed(true)
      return
    } else {
      setIsApproveNeed(false)
      return
    }
  }

  const handleOpenConnectWallet = () => {
    setOpenConnectModal(true)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!isApproveNeed) {
      await onSubmitTransaction()
    } else {
      await onApproveTransaction()
    }
  }

  const onApproveTransaction = async () => {
    const tokenContract = new web3.eth.Contract(OrbAbi, orbToken)
    const decimal = await tokenContract.methods
      .decimals()
      .call()
      .catch(() => {})

    const allowance = user.allowance

    if (allowance < +totalAmount) {
      setIsApprovePending(true)

      let gasPrice = await web3.eth.getGasPrice()

      gasPrice = +gasPrice
      gasPrice += gasPrice * 0.15

      try {
        const gasLimit = await tokenContract.methods
          .approve(bridgeToken, toBn(totalAmount.toString(), decimal))
          .estimateGas({
            from: user.address,
          })
        await tokenContract.methods
          .approve(bridgeToken, toBn(infinity.toString(), decimal))
          .send({
            from: user.address,
            gas: web3.utils.toHex(gasLimit),
            gasPrice: web3.utils.toHex(Math.round(gasPrice)),
          })
          .on('receipt', async (receipt: any) => {
            if (receipt?.status) {
              const allowance = await tokenContract.methods
                .allowance(user.address, bridgeToken)
                .call()

              if (+web3.utils.fromWei(allowance) < +totalAmount) {
                toastError('Allowance cannot be less than the entered value')
              } else {
                setIsApproveNeed(false)
                toastSuccess('Transaction Approved')
                estimateRequestBridge()
              }
              await getTokenBalance()
            } else {
              toastError('Transaction Denied')
              checkIsApproveNeed()
            }
          })
          .on('error', (error: any) => {
            toastError(error?.message || 'Error')
          })
      } catch (e) {
        await getTokenBalance()
      }

      setIsApprovePending(false)
    } else {
      toastSuccess('Transaction Approved')
      estimateRequestBridge()
    }
  }

  const onSubmitTransaction = async () => {
    const tokenContract = new web3.eth.Contract(OrbAbi, orbToken)
    const decimal = await tokenContract.methods.decimals().call()
    const addressReciever = address ? address : user.address

    setCurrentStep(TRANSACTION_STEPS.PENDING_TRANSACTION)

    try {
      const bridgeContract = new web3.eth.Contract(bridgeAbi, bridgeToken)

      let gasPrice = await web3.eth.getGasPrice()

      const gasLimit = await bridgeContract.methods
        .requestBridge(
          orbToken,
          web3.utils.hexToBytes(addressReciever),
          toBn(totalAmount.toString(), decimal),
          web3.utils.hexToNumber(
            getChainId(toNetwork || NETWORKS_ENUM.MOONRIVER)
          )
        )
        .estimateGas({ from: user.address })

      gasPrice = +gasPrice
      gasPrice += gasPrice * 0.15

      await bridgeContract.methods
        .requestBridge(
          orbToken,
          web3.utils.hexToBytes(addressReciever),
          toBn(totalAmount.toString(), decimal),
          web3.utils.hexToNumber(
            getChainId(toNetwork || NETWORKS_ENUM.MOONRIVER)
          )
        )
        .send({
          from: user.address,
          gas: web3.utils.toHex(gasLimit),
          gasPrice: web3.utils.toHex(Math.round(gasPrice)),
        })
        .on('transactionHash', async (hashTx: string) => {
          setCurrentStep(TRANSACTION_STEPS.SUBMITTED_TRANSACTION)
          addLocalTransaction({
            amount: totalAmount,
            coin: '',
            type: ETransactionStatus.PENDING,
            hashTx,
            createdAt: new Date().toUTCString(),
          })

          await fetchHistory()
        })
        .on('receipt', async (receipt: IBridgeReceipt) => {
          toastSuccess('Transaction Success')
          await getWalletData()
          await getTokenBalance()
          await fetchHistory()
        })
        .on('error', async (error: any, receipt: IBridgeReceipt) => {
          let transactions: IHistory[] = JSON.parse(
            localStorage.getItem(LOCAL_TRANSACTIONS_LS_KEY) || '[]'
          )

          if (transactions) {
            transactions = transactions.filter(
              (transaction: IHistory) =>
                transaction.hashTx !== receipt?.transactionHash
            )

            localStorage.setItem(
              LOCAL_TRANSACTIONS_LS_KEY,
              JSON.stringify(transactions)
            )

            addLocalTransaction({
              amount: totalAmount,
              coin: '',
              type: ETransactionStatus.DENIED,
              hashTx: receipt?.transactionHash || '',
              createdAt: new Date().toUTCString(),
            })
          }

          await fetchHistory()
        })
    } catch (e: any) {
      setCurrentStep(TRANSACTION_STEPS.CREATE_TRANSACTION)

      if (e?.message?.includes('User denied transaction signature')) {
        addLocalTransaction({
          amount: totalAmount,
          coin: '',
          type: ETransactionStatus.CANCELED,
          hashTx: '',
          createdAt: new Date().toUTCString(),
        })
      }

      toastError('Transaction Denied')
      await fetchHistory()
    }
  }

  const estimateRequestBridge = async () => {
    try {
      const tokenContract = new web3.eth.Contract(OrbAbi, orbToken)
      const bridgeContract = new web3.eth.Contract(bridgeAbi, bridgeToken)
      const userBalance = await web3.eth.getBalance(user.address)
      const decimal = await tokenContract.methods.decimals().call()
      const gasPrice = await web3.eth.getGasPrice()
      const addressReciever = address ? address : user.address
      const gasLimit = await bridgeContract.methods
        .requestBridge(
          orbToken,
          web3.utils.hexToBytes(addressReciever),
          toBn(totalAmount.toString(), decimal),
          web3.utils.hexToNumber(
            getChainId(toNetwork || NETWORKS_ENUM.MOONRIVER)
          )
        )
        .estimateGas({
          from: user.address,
        })

      const transactionFee = +web3.utils.fromWei(
        `${gasPrice * gasLimit}`,
        'ether'
      )
      const currentUserBalance = web3.utils.fromWei(userBalance)

      if (transactionFee > currentUserBalance) {
        setNeededGas(transactionFee - currentUserBalance)
      }

      setTransactionEstimate((state) => {
        return {
          ...state,
          networkFee: transactionFee,
          fullAmount: +totalAmount,
        }
      })
    } catch (e: any) {
      if (balance <= +totalAmount) {
        return
      } else {
        toastError(e?.message || 'Error')
      }
    }
  }

  const getAmountWithFee = (value: number) => {
    const amount = +value
    let amountMinusSystemFee: number | string =
      amount - amount * (+SYSTEM_FEE_PERCENT / 100)
    amountMinusSystemFee = amountMinusSystemFee.toFixed(18)

    return +amountMinusSystemFee
  }

  const calculateTransaction = async () => {
    try {
      setTransactionEstimate((state) => {
        return {
          ...state,
          receive: getAmountWithFee(+totalAmount),
          systemFee: +SYSTEM_FEE_PERCENT,
          networkFee: 0,
          fullAmount: +totalAmount,
        }
      })

      if (!isApproveNeed && +user?.allowance >= +totalAmount) {
        estimateRequestBridge()
      }
    } catch (e: any) {
      toastError(e?.message || 'Error')
    }
  }

  const onAmountChange = (amount: string) => {
    if (+amount < 0) {
      setTotalAmount('0')
    } else {
      setTotalAmount(amount)
    }

    if (!+amount) {
      setNeededGas(0)
    }
  }

  useEffect(() => {
    const calculate = setTimeout(() => {
      if (+totalAmount > 0) {
        calculateTransaction()
      }
    }, 1000)

    return () => clearTimeout(calculate)
  }, [totalAmount])

  const onAddressChange = (address: string) => {
    setAddress(address)
    if (address) {
      if (web3) {
        setIsAddressError(!web3.utils.isAddress(address))
      }
    } else {
      setIsAddressError(false)
    }
  }

  const onSwitchNetwork = (nextNetwork: NETWORKS_ENUM) => {
    switchNetwork(nextNetwork)
  }

  const resetState = (isGetData: boolean = false) => {
    setCurrentStep(TRANSACTION_STEPS.CREATE_TRANSACTION)
    setAddress('')
    setIsAddressError(false)
    setTotalAmount('0')
    setTransactionEstimate({
      receive: 0,
      systemFee: 0,
      networkFee: 0,
      fullAmount: 0,
    })

    if (isGetData) {
      getWalletData()
      getTokenBalance()
    }
  }

  const isWrongNetwork = fromNetwork !== currentNetwork

  const renderCurrentStep = () => {
    switch (currrentStep) {
      case TRANSACTION_STEPS.CREATE_TRANSACTION:
        return (
          <TransactionForm
            fromNetwork={fromNetwork}
            toNetwork={toNetwork}
            isConnected={isConnected}
            amount={totalAmount}
            balance={balance}
            address={address}
            isWrongNetwork={isWrongNetwork}
            isAddressError={isAddressError}
            transactionEstimate={transactionEstimate}
            isApprovePending={isApprovePending}
            onSwapNetworks={swapNetworks}
            onConnectWallet={handleOpenConnectWallet}
            onSubmit={handleSubmit}
            onAmountChange={onAmountChange}
            onAddressChange={onAddressChange}
            neededGas={neededGas}
            isNetworkPending={isNetworkPending}
            isApproveNeed={isApproveNeed}
            localPendingCount={localPendingCount}
            setOpenNetworkModal={setOpenNetworkModal}
          />
        )
      case TRANSACTION_STEPS.PENDING_TRANSACTION:
        return (
          <TransactionPending
            fromNetwork={fromNetwork}
            toNetwork={toNetwork}
            transactionEstimate={transactionEstimate}
          />
        )
      case TRANSACTION_STEPS.SUBMITTED_TRANSACTION:
        return (
          <TransactionResult
            fromNetwork={fromNetwork}
            toNetwork={toNetwork}
            transactionEstimate={transactionEstimate}
            onButtonClick={() => resetState(true)}
          />
        )
      case TRANSACTION_STEPS.SOURCE_NETWORK:
        return <TransactionSourceNetwork />
      default:
        return null
    }
  }

  const saveToNetwork = (network: NETWORKS_ENUM) => {
    setSavedToNetwork(toNetwork)

    setToNetwork(network)
  }

  return (
    <main>
      <div className={styles.content}>
        {openNetworkModal.open ? (
          <NetworkModal
            toNetwork={toNetwork}
            fromNetwork={fromNetwork}
            setToNetwork={saveToNetwork}
            setFromNetwork={setFromNetwork}
            openNetworkModal={openNetworkModal}
            setOpenNetworkModal={setOpenNetworkModal}
          />
        ) : (
          <>
            {renderCurrentStep()}
            {isConnected && isWrongNetwork ? (
              <button className={styles.content__wrong}>
                To use Orion Bridge, you’ll need to switch to the{' '}
                <div
                  className={styles.content__button}
                  onClick={() => onSwitchNetwork(fromNetwork)}
                >
                  {getNetworknNameById(Number(getChainId(fromNetwork)))} Network{' '}
                  <IconArrow className={styles.content__icon} />
                </div>
              </button>
            ) : null}
          </>
        )}
      </div>
    </main>
  )
}

export default AppContent
