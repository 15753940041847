import { NumericFormat } from 'react-number-format'
import styles from './InputDefault.module.scss'

interface InputDefaultTokenProps {
  placeholder: string
  button?: string
  buttonText?: string
  type?: 'text' | 'number'
  min?: number
  max?: number
  value?: string | number
  isError?: boolean
  errorMessage?: string
  onChange?: (value: string) => void
  onButtonClick?: () => void
  disabled?: boolean
}

function inputDefault({
  button: ButtonComponent,
  buttonText,
  placeholder,
  type = 'text',
  min = 0,
  max = 9999,
  value = '',
  onButtonClick,
  isError = false,
  errorMessage = '',
  onChange = (value: string) => {},
  disabled = false,
}: InputDefaultTokenProps) {
  return (
    <>
      <div
        className={`${styles.inputDefaultHolder} ${
          isError ? styles.inputDefaultHolderError : null
        } ${disabled ? styles.inputDefaultHolderDisabled : null}`}
      >
        <div className={styles.inputDefaultWrapper}>
          {type === 'number' ? (
            <NumericFormat
              className={styles.inputDefault}
              placeholder={placeholder}
              value={value.toString()}
              valueIsNumericString
              decimalSeparator="."
              decimalScale={18}
              thousandSeparator=","
              allowLeadingZeros={true}
              allowNegative={false}
              min={0}
              onValueChange={(value, sourceInfo) => {
                onChange(value?.value || '')
              }}
            />
          ) : (
            <input
              className={styles.inputDefault}
              type={type}
              min={min}
              max={max}
              placeholder={placeholder}
              value={value}
              onChange={(e: any) => onChange(e.target?.value || '')}
            />
          )}
          {ButtonComponent && (
            <button
              type="button"
              className={styles.inputBtn}
              onClick={onButtonClick}
            >
              {buttonText}
            </button>
          )}
        </div>
        {isError ? (
          <div className={styles.inputError}>{errorMessage}</div>
        ) : null}
      </div>
    </>
  )
}

export default inputDefault
