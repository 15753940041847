import { Tooltip } from 'react-tooltip'

import styles from './AccountTooltip.module.scss'

import { ReactComponent as SadIcon } from '../../assets/icons/sad.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icn.svg'
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg'
import { ReactComponent as LinkIcon } from '../../assets/icons/link-icon.svg'
import { ReactComponent as PendingIcon } from '../../assets/icons/pending-icon.svg'
import { ReactComponent as SuccessIcon } from '../../assets/icons/circle-arrows-icn.svg'
import { ReactComponent as DeniedIcon } from '../../assets/icons/close-icn.svg'

import { useContext, useEffect, useState } from 'react'
import { Web3Context } from '../../context'
import { copyText } from '../../utils/utils'
import { IHistory } from '../../types/transaction.interface'

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import { NumericFormat } from 'react-number-format'
import {
  ARBITRUM_CHAIN_ID,
  ARBITRUM_EXPLORER_URL,
  ETransactionStatus,
  MOONBEAM_CHAIN_ID,
  MOONBEAM_EXPLORER_URL,
  MOONRIVER_CHAIN_ID,
  MOONRIVER_EXPLORER_URL,
  ZKSYNC_CHAIN_ID,
  ZKSYNC_EXPLORER_URL,
} from '../../constants/constants'

interface IProps {
  id: string
  isOpen: boolean
  onClose: () => void
}

interface IHistoryProps {
  item: IHistory
}

dayjs.extend(RelativeTime)

const HistoryEmpty = () => {
  return (
    <div className={styles.tooltip__empty}>
      <SadIcon className={styles.tooltip__emptyIcon} />
      <p className={styles.tooltip__emptyHeading}>There's nothing here yet</p>
      <p className={styles.tooltip__emptySubheading}>
        You haven't borrowed anything yet
      </p>
    </div>
  )
}

const HistoryItem = ({ item }: IHistoryProps) => {
  const explorerLink = () => {
    if (!item?.hashTx || !item?.chainId) return null

    if (item.chainId === +MOONRIVER_CHAIN_ID) {
      return `${MOONRIVER_EXPLORER_URL}/tx/${item.hashTx}`
    }

    if (item.chainId === +MOONBEAM_CHAIN_ID) {
      return `${MOONBEAM_EXPLORER_URL}/tx/${item.hashTx}`
    }

    if (item.chainId === +ARBITRUM_CHAIN_ID) {
      return `${ARBITRUM_EXPLORER_URL}/tx/${item.hashTx}`
    }

    if (item.chainId === +ZKSYNC_CHAIN_ID) {
      return `${ZKSYNC_EXPLORER_URL}/tx/${item.hashTx}`
    }

    return null
  }
  return (
    <div className={styles.item}>
      <div className={styles.item__iconContainer}>
        {item.type === ETransactionStatus.PENDING ||
        item.type === ETransactionStatus.REQUEST_PENDING ? (
          <PendingIcon
            className={`${styles.item__icon} ${styles['item__icon--pending']}`}
          />
        ) : null}
        {item.type === ETransactionStatus.SUCCESS ||
        item.type === ETransactionStatus.REQUEST_SUCCESS ? (
          <SuccessIcon className={styles.item__icon} />
        ) : null}
        {item.type === ETransactionStatus.DENIED ||
        item.type === ETransactionStatus.CANCELED ? (
          <DeniedIcon className={styles.item__icon} />
        ) : null}
      </div>
      <div className={styles.item__info}>
        <div className={styles.item__statusContainer}>
          <p
            className={`${styles.item__status} ${
              item.type === ETransactionStatus.SUCCESS
                ? styles['item__status--success']
                : null
            }
             ${
               item.type === ETransactionStatus.DENIED ||
               item.type === ETransactionStatus.CANCELED
                 ? styles['item__status--denied']
                 : null
             }`}
          >
            {item.type === ETransactionStatus.REQUEST
              ? 'Receive'
              : item.type === ETransactionStatus.PENDING ||
                item.type === ETransactionStatus.REQUEST_PENDING
              ? 'Pending...'
              : item.type === ETransactionStatus.DENIED
              ? 'Denied'
              : item.type === ETransactionStatus.SUCCESS
              ? 'Sent'
              : item.type === ETransactionStatus.REQUEST_SUCCESS
              ? 'Received'
              : item.type}
          </p>
          {explorerLink() ? (
            <a href={explorerLink() || ''} target="_blank" rel="noreferrer">
              <LinkIcon className={styles.item__linkIcon} />
            </a>
          ) : null}
        </div>
        <div className={styles.item__time}>
          {dayjs(item.createdAt).fromNow()}
        </div>
      </div>
      {item?.amount ? (
        <div className={styles.item__amount}>
          <NumericFormat
            value={item.amount}
            displayType="text"
            decimalSeparator="."
            thousandSeparator=","
            allowLeadingZeros
            valueIsNumericString
            decimalScale={4}
            fixedDecimalScale
          />{' '}
          ORB
        </div>
      ) : null}
    </div>
  )
}

export const AccountTooltip = ({ id, isOpen = false, onClose }: IProps) => {
  const { disconnectWallet, user, fetchHistory, history } =
    useContext(Web3Context)
  const [isCopied, setIsCopied] = useState<boolean>(false)

  useEffect(() => {
    if (isOpen) {
      fetchHistory()
    }
  }, [isOpen])

  console.log(history)

  return (
    <Tooltip
      isOpen={isOpen}
      className={styles.tooltip}
      id={id}
      content=""
      clickable
      noArrow
    >
      <div className={styles.tooltip__content}>
        <div className={styles.tooltip__header}>
          <p className={styles.tooltip__heading}>Account</p>
          <button
            className={styles.tooltip__close}
            type="button"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </div>
        <div className={styles.tooltip__account}>
          <div className={styles.tooltip__avatarContainer}>
            <Jazzicon
              diameter={42}
              seed={jsNumberForAddress(user?.address || '')}
            />
          </div>
          <div className={styles.tooltip__info}>
            <p className={styles.tooltip__connected}>Connected with Metamask</p>
            <div className={styles.tooltip__address}>
              {user?.addressShort || ''}
              <button
                data-tooltip-id="copy_text"
                className={styles.tooltip__copy}
                type="button"
                onClick={async (e: React.SyntheticEvent) => {
                  e.stopPropagation()
                  await copyText(user?.address || '')
                  setIsCopied(true)
                }}
              >
                <CopyIcon className={styles.tooltip__copyIcon} />
              </button>
              <Tooltip
                id="copy_text"
                content={isCopied ? 'Copied' : 'Copy address'}
              />
            </div>
          </div>
          <button
            className={styles.tooltip__disconnect}
            type="button"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              disconnectWallet()
              onClose()
            }}
          >
            Disconnect
          </button>
        </div>

        <p className={styles.tooltip__label}>Transactions</p>

        {history.length ? (
          <div className={styles.tooltip__list}>
            {history.map((item: IHistory, i: number) => (
              <HistoryItem item={item} key={i} />
            ))}
          </div>
        ) : (
          <HistoryEmpty />
        )}
      </div>
    </Tooltip>
  )
}
