import React from 'react'
import styles from './InputToken.module.scss'

interface InputTokenProps {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  text: string
}

function InputToken({ icon: IconToken, text }: InputTokenProps) {
  return (
    <>
      <div className={styles.inputHolder}>
        {' '}
        {IconToken && <IconToken className={styles.icon} />}
        <input className={styles.inputToken} value={text} readOnly />
        <label className={styles.inputLabel}>Token</label>
      </div>
    </>
  )
}

export default InputToken
