import Button from '../Button/Button'
import InputDefault from '../InputDefault/InputDefault'
import InputToken from '../InputToken/InputToken'
import Receive from '../Receive/Receive'
import SelectToken from '../SelectToken/SelectToken'

import { ReactComponent as IconCircleArrow } from '../../assets/icons/circle-arrows-icn.svg'
import { ReactComponent as IconOrb } from '../../assets/icons/orb-icn.svg'

import styles from './TransactionForm.module.scss'
import { NETWORKS_ENUM } from '../../constants/constants'
import { AppCheckbox } from '../AppCheckbox/AppCheckbox'
import { useEffect, useState } from 'react'
import { ITransactionEstimate } from '../../types/transaction.interface'
import { commify, getChainIdNumber } from '../../utils/utils'

interface IProps {
  onSwapNetworks: () => void
  onConnectWallet: () => void
  onSubmit: (e: React.SyntheticEvent) => void
  onAmountChange: (amount: string) => void
  onAddressChange: (address: string) => void
  fromNetwork: NETWORKS_ENUM
  toNetwork: NETWORKS_ENUM
  isConnected: boolean
  amount: string
  balance: number
  isWrongNetwork: boolean
  address: string
  isAddressError: boolean
  transactionEstimate: ITransactionEstimate | null
  isApprovePending: boolean
  neededGas: any
  isNetworkPending: boolean
  isApproveNeed: boolean
  localPendingCount: number
  setOpenNetworkModal: (a: { open: boolean; isSource: boolean }) => void
}

export const TransactionForm = ({
  onSwapNetworks,
  fromNetwork,
  toNetwork,
  isConnected = false,
  onConnectWallet,
  onSubmit,
  onAmountChange,
  amount = '0',
  balance = 0,
  isWrongNetwork = false,
  address = '',
  isAddressError = false,
  transactionEstimate = null,
  isApprovePending = false,
  isNetworkPending = false,
  isApproveNeed = false,
  onAddressChange = () => {},
  localPendingCount = 0,
  neededGas = 0,
  setOpenNetworkModal,
}: IProps) => {
  const [isAnotherWallet, setIsAnotherWallet] = useState<boolean>(false)

  useEffect(() => {
    if (address) {
      onAddressChange('')
    }
  }, [isAnotherWallet])

  const formatBalance = () => {
    const comasCount = Math.floor(
      (balance.toString().split('.')[0].length - 1) / 3
    )
    const sliceValue = 16 - comasCount

    return balance.toString().slice(0, sliceValue)
  }

  const setMaxAmount = () => {
    onAmountChange(formatBalance())
  }

  const handleAddressChange = (value: string) => {
    onAddressChange(value)
  }

  const isValid = () => {
    if (isWrongNetwork) return false
    if (isAddressError) return false
    if (!amount) return false
    if (+amount <= 0) return false
    if (+amount > balance) return false
    if (isAnotherWallet && (!address || isAddressError)) return false

    return true
  }

  const renderButton = () => {
    if (!isConnected) {
      return (
        <Button
          textBtn="Connect Wallet"
          disabled={isNetworkPending || !!localPendingCount}
          type="button"
          onClick={onConnectWallet}
        />
      )
    }

    if (isConnected && isWrongNetwork) {
      return <Button textBtn="Wrong Network" disabled={true} type="submit" />
    }

    if (isApproveNeed) {
      return (
        <Button
          textBtn={'Approve'}
          disabled={
            !isValid() ||
            isApprovePending ||
            isNetworkPending ||
            !!localPendingCount
          }
          pending={isApprovePending}
          type="submit"
        />
      )
    }

    if (neededGas) {
      return (
        <Button
          textBtn={`Not enough gas ${neededGas}`}
          type="button"
          disabled
        />
      )
    }

    if (!isApproveNeed) {
      return (
        <Button
          textBtn="Confirm transaction"
          disabled={!isValid() || isNetworkPending || !!localPendingCount}
          type="submit"
        />
      )
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className={styles.headingHolder}>
        <h1>Bridge</h1>
      </div>
      <div className={styles.formRow}>
        <div className={styles.labelRow}>
          <label>From</label>
        </div>
        <div className={styles.inputRow}>
          <InputToken icon={IconOrb} text="ORB" />
          <SelectToken
            setOpenNetworkModal={() =>
              setOpenNetworkModal({ open: true, isSource: true })
            }
            chainId={getChainIdNumber(fromNetwork) || ''}
          />
        </div>
        <div className={styles.center}>
          <button
            className={styles.swapButton}
            type="button"
            disabled={
              isApprovePending || isNetworkPending || !!localPendingCount
            }
            onClick={() => onSwapNetworks()}
          >
            <IconCircleArrow />
          </button>
        </div>
        <div className={styles.labelRow}>
          <label>To</label>
        </div>
        <div className={styles.inputRow}>
          <InputToken icon={IconOrb} text="ORB" />
          <SelectToken
            setOpenNetworkModal={() =>
              setOpenNetworkModal({ open: true, isSource: false })
            }
            chainId={getChainIdNumber(toNetwork) || ''}
          />
        </div>
        <div className={styles.labelRow}>
          <label>Total Amount</label>
          <label>
            Balance: <span>{commify(formatBalance())}</span> ORB
          </label>
        </div>
        <div className={styles.inputRow}>
          <InputDefault
            placeholder="0.0"
            type="number"
            button="button"
            buttonText="MAX"
            isError={+amount > balance}
            value={amount}
            disabled={
              !balance ||
              !isConnected ||
              isApprovePending ||
              isNetworkPending ||
              isWrongNetwork
            }
            onChange={(value: string) => onAmountChange(value)}
            onButtonClick={setMaxAmount}
          />
        </div>

        <div className={styles.checkbox}>
          <AppCheckbox
            label="Receive on another wallet"
            name="destination"
            disabled={isApprovePending || isNetworkPending}
            isChecked={isAnotherWallet}
            onChange={(value: boolean) => setIsAnotherWallet(value)}
          />
        </div>

        {isAnotherWallet ? (
          <>
            <div className={styles.labelRow}>
              <label>Destination address</label>
            </div>
            <div className={styles.inputRow}>
              <InputDefault
                type="text"
                placeholder="Enter the destination wallet address"
                value={address}
                isError={isAddressError}
                disabled={
                  !isConnected ||
                  !balance ||
                  isApprovePending ||
                  isNetworkPending
                }
                errorMessage="Incorrect wallet address"
                onChange={(value: string | number) =>
                  handleAddressChange(value.toString())
                }
              />
            </div>
          </>
        ) : null}

        {+amount > 0 ? (
          <Receive data={transactionEstimate} fromNetwork={fromNetwork} />
        ) : null}
      </div>

      {renderButton()}
    </form>
  )
}
