import { createContext } from 'react'
import { IUser } from './App'
import { NETWORKS_ENUM } from './constants/constants'
import { IHistory } from './types/transaction.interface'

interface IWeb3Context {
  isConnected: boolean
  provider: any
  web3: any
  user: IUser
  currentNetwork: NETWORKS_ENUM | null
  balance: number
  isNetworkPending: boolean
  orbToken: string | null
  bridgeToken: string | null
  bridgeAbi: string | null
  localTransactions: IHistory[]
  history: IHistory[]
  openConnectModal: boolean
  pendingCount: number
  localPendingCount: number
  isNetworkError: boolean
  fetchHistory: () => Promise<void>
  connectWallet: (a: string) => Promise<void>
  disconnectWallet: () => void
  switchNetwork: (nextNetwork: NETWORKS_ENUM) => Promise<void>
  getWalletData: () => Promise<void>
  getTokenBalance: () => Promise<void>
  addLocalTransaction: (transaction: IHistory) => void
  getLocalTransactions: () => void
  setOpenConnectModal: (a: boolean) => void
}

export const Web3Context = createContext<IWeb3Context>({
  isConnected: false,
  provider: null,
  web3: null,
  currentNetwork: null,
  user: {
    address: null,
    addressShort: null,
    chainId: null,
    connected: false,
    symbol: '',
    allowance: 0,
  },
  balance: 0,
  isNetworkPending: false,
  orbToken: null,
  bridgeAbi: '',
  bridgeToken: '',
  localTransactions: [],
  history: [],
  openConnectModal: false,
  pendingCount: 0,
  localPendingCount: 0,
  isNetworkError: false,
  setOpenConnectModal: (a: boolean) => {},
  fetchHistory: async () => {},
  connectWallet: async (a: string) => {},
  switchNetwork: async () => {},
  disconnectWallet: () => {},
  getWalletData: async () => {},
  getTokenBalance: async () => {},
  addLocalTransaction: () => {},
  getLocalTransactions: () => {},
})
