import React from 'react'
import styles from './LabelButton.module.scss'

import { ReactComponent as PendingIcon } from '../../assets/icons/pending-icon.svg'
interface LabelButtonProps {
  textValue: string
  textName?: string
  onButtonClick: (e: React.SyntheticEvent) => void
}

function LabelButton({ textValue, textName, onButtonClick }: LabelButtonProps) {
  return (
    <button
      id="pending-button"
      className={styles.button}
      type="button"
      onClick={onButtonClick}
    >
      <PendingIcon className={styles.button__icon} />
      <span className={styles.textValue}>{textValue}</span>
      <span className={styles.textName}>{textName}</span>
    </button>
  )
}

export default LabelButton
