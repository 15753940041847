import styles from './WalletButton.module.scss'

import { ReactComponent as IconWallet } from '../../assets/icons/wallet-icon.svg'
import { ReactComponent as IconSwap } from '../../assets/icons/swap-icon.svg'

import { NETWORKS_ENUM } from '../../constants/constants'
import { AccountTooltip } from '../AccountTooltip/AccountTooltip'
import AppLoader from '../AppLoader/AppLoader'

import OutsideClickHandler from 'react-outside-click-handler'
import NetworkIcon from '../NetworkIcon/NetworkIcon'
import { getChainIdNumber } from '../../utils/utils'
import { WalletModal } from '../WalletModal/WalletModal'

interface IProps {
  text?: string
  isConnected: boolean
  currentNetwork?: NETWORKS_ENUM | null
  isPending: boolean
  isOpen: boolean
  onButtonClick?: (a: string) => void
  onConnectedButtonClick?: (e: React.SyntheticEvent) => void
  onCloseTransactions: () => void
  setIsOpenModal: (a: boolean) => void
  isOpenModal: boolean
}

function WalletButton({
  text = '',
  isConnected = false,
  currentNetwork = null,
  isPending = false,
  isOpen = false,
  onButtonClick = (a) => {},
  onConnectedButtonClick = (e: React.SyntheticEvent) => {},
  onCloseTransactions = () => {},
  setIsOpenModal,
  isOpenModal,
}: IProps) {
  const handleClick = (e: React.SyntheticEvent) => {
    if (isConnected) {
      onConnectedButtonClick(e)
    } else {
      setIsOpenModal(true)
    }
  }

  return (
    <div className={styles.button__wrapper}>
      <button
        id="wallet-button"
        data-tooltip-id="my-tooltip"
        type="button"
        disabled={isPending}
        className={styles.button}
        onClick={handleClick}
      >
        {!isConnected ? (
          <>
            {isPending ? (
              <AppLoader />
            ) : (
              <IconWallet className={styles.button__icon} />
            )}

            <span className={styles.button__text}>Connect Wallet</span>
          </>
        ) : (
          <>
            <IconSwap
              className={`${styles.button__icon} ${styles['button__icon--swap']}`}
            />
            <div className={styles.button__separator}></div>
            <NetworkIcon chainId={getChainIdNumber(currentNetwork) || ''} />
            <span className={styles.button__text}>{text}</span>
          </>
        )}
      </button>
      <OutsideClickHandler onOutsideClick={() => setIsOpenModal(false)}>
        {isOpenModal ? (
          <WalletModal
            onButtonClick={onButtonClick}
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
            id="my-tooltip"
            onClose={() => setIsOpenModal(false)}
          />
        ) : (
          <div></div>
        )}
      </OutsideClickHandler>
      {isOpen ? (
        <OutsideClickHandler onOutsideClick={() => onCloseTransactions()}>
          <AccountTooltip
            isOpen={isOpen}
            id="my-tooltip"
            onClose={() => onCloseTransactions()}
          />
        </OutsideClickHandler>
      ) : null}
    </div>
  )
}

export default WalletButton
