import styles from './WalletModal.module.scss'
import { ReactComponent as MetamaskIcon } from '../../assets/icons/MetaMaskIcon.svg'
import { ReactComponent as WalletConnectIcon } from '../../assets/icons/walletConnectIcon.svg'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'

interface IProps {
  id: any
  onClose: () => void
  onButtonClick: (a: string) => void
  isOpen: boolean
  setIsOpen: (a: boolean) => void
}

dayjs.extend(RelativeTime)

export const WalletModal = ({
  id = false,
  onClose,
  onButtonClick = (a) => {},
}: IProps) => {
  const handleClick = (type: string) => {
    const isMobile = window.innerWidth <= 768
    if (isMobile && !window.ethereum) {
      window.location.replace(
        `https://metamask.app.link/dapp/${window.location.host}/`
      )
    } else {
      onClose()
      onButtonClick(type)
    }
  }

  return (
    <div className={styles.tooltip} id={id}>
      <div className={styles.tooltip__content}>
        <button
          className={styles.tooltip__button}
          onClick={() => handleClick('metamask')}
        >
          <MetamaskIcon />
          Metamask
        </button>
        {/*<br />*/}
        {/*<button*/}
        {/*  onClick={() => handleClick('walletconnect')}*/}
        {/*  className={styles.tooltip__button}*/}
        {/*>*/}
        {/*  <WalletConnectIcon />*/}
        {/*  Wallet connect*/}
        {/*</button>*/}
      </div>
    </div>
  )
}
