import { ReactComponent as IconMoonriver } from '../../assets/icons/movr-icn.svg'
import { ReactComponent as IconMoonbeam } from '../../assets/icons/moonbeam-icn.svg'
import { ReactComponent as IconGoerli } from '../../assets/icons/goerli-icon.svg'
import { ReactComponent as IconZksync } from '../../assets/icons/zksync_era.svg'

interface IProps {
  chainId: string
  size?: number
}

const NetworkIcon = ({ chainId, size = 24 }: IProps) => {
  const chains = {
    1287: <IconMoonbeam style={{ width: `${size}px`, height: `${size}px` }} />,
    421613: <IconGoerli style={{ width: `${size}px`, height: `${size}px` }} />,
    42161: <IconGoerli style={{ width: `${size}px`, height: `${size}px` }} />,
    1285: <IconMoonriver style={{ width: `${size}px`, height: `${size}px` }} />,
    1284: <IconMoonbeam style={{ width: `${size}px`, height: `${size}px` }} />,
    280: <IconZksync style={{ width: `${size}px`, height: `${size}px` }} />,
    324: <IconZksync style={{ width: `${size}px`, height: `${size}px` }} />,
  }

  if (+chainId && +chainId in chains) {
    //@ts-ignore
    return chains[+chainId]
  } else {
    return null
  }
}

export default NetworkIcon
