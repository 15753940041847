import styles from './NetworkName.module.scss'
import NetworkIcon from '../NetworkIcon/NetworkIcon'

import chains from '../../assets/data/chains.json'

interface NetworkNameProps {
  chainId: string
  showName?: boolean
}

function NetworkName({ chainId, showName = true }: NetworkNameProps) {
  return (
    <div className={styles.networkHolder}>
      <span className={styles.network}>
        <NetworkIcon chainId={chainId} />
      </span>
      {showName ? (
        <span className={styles.networkName}>
          {chains.find((chain) => chain.chainId === +chainId)?.name}
        </span>
      ) : null}
    </div>
  )
}

export default NetworkName
