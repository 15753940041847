import React from 'react'
import { toast } from 'react-toastify'
import { ReactComponent as CheckIcon } from '../assets/icons/check-icon.svg'
import { ReactComponent as ErrorIcon } from '../assets/icons/error-icon.svg'

export function toastSuccess(nodeOrMsg: string | React.ReactNode) {
  return toast.success(<div>{nodeOrMsg}</div>, {
    icon: <CheckIcon className="toast__icon--success" />,
  })
}

export function toastError(nodeOrMsg: string | React.ReactNode) {
  return toast.error(<div>{nodeOrMsg}</div>, {
    icon: <ErrorIcon className="toast__icon--error" />,
  })
}
