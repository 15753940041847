// import { ReactComponent as IconMoonbeam } from '../../assets/icons/moonbeam-icn.svg'
// import { ReactComponent as IconMovr } from '../../assets/icons/movr-icn.svg'
import CloseIcon from '../../assets/icons/close-icn.svg'

import styles from './TransactionSourceNetwork.module.scss'

export const TransactionSourceNetwork = () => {
  return (
    <div>
      <div className={styles.headingHolder}>
        <h1>Source Network</h1>
        <span>
          <img src={CloseIcon} alt="close" />
        </span>
      </div>
      <div className={styles.networkBlock}>
        <span className={styles.ttl}>From</span>
        {/* <NetworkName icon={IconMovr} text="Moonriver" /> */}
        {/* <NetworkName icon={IconMoonbeam} text="Moonbeam" /> */}
      </div>
    </div>
  )
}
