// @ts-nocheck
import { AppHeader } from './components/AppHeader/AppHeader'
import AppContent from './components/AppContent/AppContent'
import Web3 from 'web3'
import { fromBn } from 'evm-bn'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

import { useEffect, useState } from 'react'
import { Web3Context } from './context'
import {
  ARBITRUM_BRIDGE_CONTRACT_ADDRESS,
  ARBITRUM_CHAIN_ID,
  ARBITRUM_CHAIN_ID_HEX,
  ARBITRUM_RPC_URL,
  ETransactionStatus,
  LOCAL_TRANSACTIONS_LS_KEY,
  MOONBEAM_CHAIN_ID,
  MOONBEAM_CHAIN_ID_HEX,
  MOONBEAM_RPC_URL,
  MOONRIVER_CHAIN_ID,
  MOONRIVER_CHAIN_ID_HEX,
  MOONRIVER_RPC_URL,
  NETWORKS_ENUM,
  ZKSYNC_CHAIN_ID,
  ZKSYNC_CHAIN_ID_HEX,
  ZKSYNC_RPC_URL,
} from './constants/constants'

import {
  getBridgeAbi,
  getBridgeToken,
  getNetworkName,
  getOrbToken,
  mergeByProperty,
} from './utils/utils'
import OrbAbi from './contracts/OrbABI.json'

import { Slide, ToastContainer } from 'react-toastify'
import { toastError } from './utils/toast'
import { IHistory } from './types/transaction.interface'

import chains from './assets/data/chains.json'
import dayjs from 'dayjs'
import 'react-toastify/dist/ReactToastify.css'

const WalletType = {
  metamask: 'metamask',
  walletconnect: 'walletconnect',
}

export interface IUser {
  address: string | null
  addressShort: string | null
  chainId: number | null
  connected: boolean
  symbol: string
  allowance: number
}

let provider = {
  [WalletType.metamask]: undefined, // required, not null
  [WalletType.walletconnect]: undefined, // required, not null
}

let web3: any = null

function App() {
  const [user, setUser] = useState<IUser>({
    address: null,
    addressShort: null,
    chainId: null,
    connected: false,
    symbol: '',
    allowance: 0,
  })
  const [balance, setBalance] = useState(0)
  const [currentNetwork, setCurrentNetwork] = useState<NETWORKS_ENUM | null>(
    null
  )
  const [isNetworkPending, setIsNetworkPending] = useState(false)
  const [orbToken, setOrbToken] = useState<string | null>(null)
  const [bridgeToken, setBridgeToken] = useState<string | null>('')
  const [bridgeAbi, setBridgeAbi] = useState('')
  const [localTransactions, setLocalTransactions] = useState<IHistory[]>([])
  const [history, setHistory] = useState<IHistory[]>([])
  const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(false)
  const [openConnectModal, setOpenConnectModal] = useState(false)
  const [pendingCount, setPendingCount] = useState(0)
  const [localPendingCount, setLocalPendingCount] = useState(0)
  const [isNetworkError, setIsNetworkError] = useState(false)

  const handleCurrentNetwork = (chainId: string) => {
    const network: NETWORKS_ENUM | null = getNetworkName(chainId) || null

    setCurrentNetwork(network)
    setOrbToken(getOrbToken(network))
    setBridgeToken(getBridgeToken(network))
    setBridgeAbi(getBridgeAbi(network))
  }

  useEffect(() => {
    const fetchData = async () => {
      await getTokenBalance()
    }

    if (
      currentNetwork === NETWORKS_ENUM.MOONBEAM ||
      currentNetwork === NETWORKS_ENUM.MOONRIVER ||
      currentNetwork === NETWORKS_ENUM.ZKSYNC ||
      currentNetwork === NETWORKS_ENUM.ARBITRUM
    ) {
      fetchData()
    }
  }, [currentNetwork])

  useEffect(() => {
    const fetchData = async () => {
      await getTokenBalance()
    }

    fetchData()
  }, [user.address])

  useEffect(() => {
    getLocalTransactions()
  }, [])

  const [intervalCount, setIntervalCount] = useState(0)
  useEffect(() => {
    let interval = setInterval(() => {
      setIntervalCount((state) => (state += 1))
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [user.address, localTransactions?.length])

  useEffect(() => {
    fetchHistory()
  }, [intervalCount])

  useEffect(() => {
    const walletType = getWalletType()
    if (walletType) {
      connectWallet(walletType)
    }
  }, [])

  const getTokenBalance = async () => {
    try {
      if (orbToken) {
        const contract = new web3.eth.Contract(OrbAbi, orbToken)
        const decimal = await contract.methods
          .decimals()
          .call()
          .catch((e: any) => {})

        const result = await contract.methods
          .balanceOf(user.address)
          .call({
            from: user.address,
          })
          .catch((e: any) => {
            setBalance(0)
          })

        if (result && decimal) {
          setBalance(+fromBn(result, decimal))
        }

        const tokenContract = new web3.eth.Contract(OrbAbi, orbToken)

        const allowance = await tokenContract.methods
          .allowance(user.address, bridgeToken)
          .call()

        setUser((state) => {
          return {
            ...state,
            allowance: allowance ? +web3.utils.fromWei(allowance) : 0,
          }
        })
      } else {
        setBalance(0)
      }
    } catch (error: any) {
      setBalance(0)
    }
  }

  const createProviderMetamask = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        switch (true) {
          case provider[WalletType.metamask] === undefined:
          case provider[WalletType.metamask] === null:
            provider[WalletType.metamask] =
              'ethereum' in window ? window['ethereum'] : Web3.givenProvider
            break
          default:
        }

        web3 = new Web3(provider[WalletType.metamask] || Web3.givenProvider)

        if (!user.connected) {
          await web3.eth.requestAccounts().catch((e: any) => {
            if (e.message.includes('Provider not set or invalid')) {
              toastError('Please install Metamask')
            } else {
              toastError(e?.message || 'Error')
            }
          })
        }
      } catch (error: any) {
        if (!error?.code) {
          window.open(
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
            '_blank'
          )
        }
        return reject(null)
      }

      try {
        await getWalletData()

        if (user.address) {
          await fetchHistory()
        }

        if (provider[WalletType.metamask] !== undefined) {
          provider[WalletType.metamask]?.removeAllListeners('accountsChanged')
          provider[WalletType.metamask]?.removeAllListeners('chainChanged')
          provider[WalletType.metamask]?.removeAllListeners('disconnect')

          provider[WalletType.metamask]?.on('accountsChanged', async () => {
            deleteAllLocalTransactions()
            await getWalletData()
          })
          provider[WalletType.metamask]?.on(
            'chainChanged',
            async (chainIdLocal: string) => {
              await getWalletData()
              setIsNetworkPending(false)
            }
          )

          provider[WalletType.metamask]?.on('disconnect', () => {
            removeWalletData()
          })
        }

        return resolve(null)
      } catch (error) {
        return reject(null)
      }
    })
  }

  const createProviderWalletConnect = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        switch (true) {
          case !provider[WalletType.walletconnect]:
          case !provider[WalletType.walletconnect]?.connected:
            provider[WalletType.walletconnect] = await new Web3Modal({
              disableInjectedProvider: true,
              cacheProvider: false,
              providerOptions: {
                [WalletType.walletconnect]: {
                  package: WalletConnectProvider,
                  options: {
                    rpc: {
                      [MOONBEAM_CHAIN_ID]: MOONBEAM_RPC_URL,
                      [MOONRIVER_CHAIN_ID]: MOONRIVER_RPC_URL,
                      [ARBITRUM_CHAIN_ID]: ARBITRUM_RPC_URL,
                      [ZKSYNC_CHAIN_ID]: ZKSYNC_RPC_URL,
                    },
                  },
                },
              },
            }).connectTo(WalletType.walletconnect)

            break
          default:
        }

        web3 = new Web3(provider[WalletType.walletconnect])
        let localChainId = null
        try {
          localChainId = await web3.eth.net.getId()
        } catch (error) {
          console.error(error)
        }
        if ('enable' in web3.currentProvider) {
          await web3.currentProvider.enable()
        }

        await getWalletData()

        provider[WalletType.walletconnect]?.removeAllListeners(
          'accountsChanged'
        )
        provider[WalletType.walletconnect]?.removeAllListeners('chainChanged')
        provider[WalletType.walletconnect]?.removeAllListeners('disconnect')

        provider[WalletType.walletconnect]?.on('accountsChanged', async () => {
          await getWalletData()
        })

        provider[WalletType.walletconnect]?.on('disconnect', () => {
          removeWalletData()
        })

        return resolve(null)
      } catch (error) {
        return reject(null)
      }
    })
  }

  const getWalletData = async () => {
    const [wallet] = await web3.eth.getAccounts().catch(() => {})

    if (!wallet) {
      return await removeWalletData()
    }

    let chainId: number | null = null

    try {
      chainId = await web3.eth.net.getId().catch(() => {})
    } catch (error: any) {
      toastError(error?.message || 'Error')
    }

    setUser((state) => {
      return {
        ...state,
        address: wallet,
        addressShort: `${wallet.substr(0, 5)}...${wallet.substr(
          wallet.length - 4
        )}`,
        chainId,
        symbol:
          chains?.find((chain) => chain.chainId === chainId)?.nativeCurrency
            ?.symbol || '',
        connected: true,
      }
    })

    if (chainId) {
      handleCurrentNetwork(web3.utils.numberToHex(chainId))
    }
    await getTokenBalance()
  }

  const removeWalletData = async () => {
    setUser({
      address: null,
      addressShort: null,
      chainId: null,
      connected: false,
      symbol: '',
      allowance: 0,
    })
  }

  const connectWallet = async (walletType: string): Promise<any> => {
    setWalletType(walletType)
    try {
      if (walletType === WalletType.walletconnect) {
        await createProviderWalletConnect()
      }

      if (walletType === WalletType.metamask) {
        await createProviderMetamask()
      }
    } catch (error) {
      setUser((user) => {
        return {
          ...user,
          connected: false,
        }
      })
    }
  }

  const switchNetwork = async (nextNetwork: NETWORKS_ENUM) => {
    if (!user.connected) {
      return
    }

    try {
      let switchToChain: string | undefined = undefined

      switch (nextNetwork) {
        case NETWORKS_ENUM.MOONRIVER:
          switchToChain = MOONRIVER_CHAIN_ID_HEX
          break
        case NETWORKS_ENUM.MOONBEAM:
          switchToChain = MOONBEAM_CHAIN_ID_HEX
          break
        case NETWORKS_ENUM.ZKSYNC:
          switchToChain = ZKSYNC_CHAIN_ID_HEX
          break
        case NETWORKS_ENUM.ARBITRUM:
          switchToChain = ARBITRUM_CHAIN_ID_HEX
          break
      }

      if (switchToChain) {
        setIsNetworkPending(true)
        await web3.currentProvider
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: switchToChain }],
          })
          .catch(async (error: any) => {
            if (error.message.includes('Unrecognized chain ID')) {
              const chain = chains.find(
                (chain) =>
                  chain.chainId === web3.utils.hexToNumber(switchToChain)
              )

              await web3.currentProvider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: switchToChain,
                    rpcUrls: chain?.rpc || [],
                    chainName: chain?.name || '',
                    nativeCurrency: chain?.nativeCurrency,
                    blockExplorerUrls: [chain?.explorers?.[0]?.url || ''],
                  },
                ],
              })

              switchNetwork(nextNetwork)
            } else {
              onNetworkError()
              setIsNetworkPending(false)
            }
          })

        setIsNetworkPending(false)
      }
    } catch (e: any) {
      onNetworkError()
      toastError(e?.message || 'Error')
    }
  }

  const disconnectWallet = () => {
    removeWalletType()
    provider[WalletType.walletconnect] = undefined
    removeWalletData()
    setBalance(0)
    localStorage.removeItem('walletconnect')
    return true
  }

  const addLocalTransaction = (transaction: IHistory) => {
    const transactions = [transaction, ...localTransactions]

    localStorage.setItem(
      LOCAL_TRANSACTIONS_LS_KEY,
      JSON.stringify(transactions)
    )

    setLocalTransactions(transactions)
  }

  const getLocalTransactions = () => {
    const transactions = localStorage.getItem(LOCAL_TRANSACTIONS_LS_KEY)
    setLocalTransactions(JSON.parse(transactions || '[]'))
  }

  const deleteAllLocalTransactions = () => {
    localStorage.clear()
  }

  const getWalletType = () => {
    return localStorage.getItem('wallet-type') || WalletType.metamask
  }

  const setWalletType = (walletType: string) => {
    localStorage.setItem('wallet-type', walletType)
  }

  const removeWalletType = () => {
    localStorage.removeItem('wallet-type')
  }

  const fetchHistory = async () => {
    if (!user?.address) return
    if (isLoadingHistory) return

    setIsLoadingHistory(true)

    try {
      const { data } = await fetch(
        `${process.env.REACT_APP_BRIDGE_API_URL}/api/bridge/transaction-history/${user.address}`
      )
        .then((response) => {
          return response.json()
        })
        .catch(() => {})

      const local =
        JSON.parse(localStorage.getItem(LOCAL_TRANSACTIONS_LS_KEY)) || []

      let allTransactions = mergeByProperty(local, data, 'hashTx')

      allTransactions = allTransactions.map((transaction: IHistory) => {
        return {
          ...transaction,
          createdAt: dayjs(transaction.createdAt).valueOf(),
        }
      })

      allTransactions.sort((a, b) => {
        return a.createdAt - b.createdAt
      })

      allTransactions.reverse()

      setHistory([...allTransactions])

      const pendingTransactions = allTransactions.filter(
        (tr: IHistory) =>
          tr.type === ETransactionStatus.PENDING ||
          tr.type === ETransactionStatus.REQUEST_PENDING
      )

      setPendingCount(pendingTransactions?.length || 0)

      const localPendingTransactions = allTransactions.filter(
        (tr: IHistory) => tr.type === ETransactionStatus.PENDING
      )

      setLocalPendingCount(localPendingTransactions?.length || 0)
    } catch (e: any) {}

    setIsLoadingHistory(false)
  }

  const onNetworkError = () => {
    setIsNetworkError(true)

    setTimeout(() => {
      setIsNetworkError(false)
    }, 1000)
  }

  return (
    <>
      <div className="starsBackground"></div>
      <div className="wrapperBackground"></div>
      <Web3Context.Provider
        value={{
          isConnected: user.connected,
          provider,
          web3,
          connectWallet,
          user,
          currentNetwork,
          balance,
          switchNetwork,
          disconnectWallet,
          getWalletData,
          isNetworkPending,
          orbToken,
          bridgeToken,
          bridgeAbi,
          openConnectModal,
          setOpenConnectModal,
          getTokenBalance,
          addLocalTransaction,
          localTransactions,
          getLocalTransactions,
          history,
          fetchHistory,
          pendingCount,
          localPendingCount,
          isNetworkError,
        }}
      >
        <div className="wrapper">
          <AppHeader onConnectWallet={connectWallet} />
          <AppContent />
          <ToastContainer
            transition={Slide}
            closeButton={false}
            hideProgressBar
            className={`toast`}
          />
        </div>
      </Web3Context.Provider>
    </>
  )
}

export default App
