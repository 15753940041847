export enum NETWORKS_ENUM {
  MOONRIVER = 'moonriver',
  MOONBEAM = 'moonbeam',
  ARBITRUM = 'arbitrum goerli',
  ZKSYNC = 'zksync era',
}

export const NETWORKS = Object.keys(
  NETWORKS_ENUM
) as (keyof typeof NETWORKS_ENUM)[]

export enum ETransactionStatus {
  SUCCESS = 'Success',
  REQUEST = 'Request',
  PENDING = 'Pending',
  DENIED = 'Decline',
  CANCELED = 'Cancelled',
  REQUEST_PENDING = 'Request_Pending',
  REQUEST_SUCCESS = 'Request_Success',
}

export const LOCAL_TRANSACTIONS_LS_KEY = 'orion_local_transactions_list'

export const SYSTEM_FEE_PERCENT = process.env.REACT_APP_SYSTEM_FEE || 0
export const GAS_LIMIT = 12990000
export const ORB_MOONRIVER_TOKEN =
  process.env.REACT_APP_ORB_MOONRIVER_CONTRACT_ADDRESS || ''
export const ORB_MOONBEAM_TOKEN =
  process.env.REACT_APP_ORB_MOONBEAM_CONTRACT_ADDRESS || ''
export const ORB_ZKSYNC_TOKEN =
  process.env.REACT_APP_ORB_ZKSYNC_CONTRACT_ADDRESS || ''
export const ORB_ARBITRUM_TOKEN =
  process.env.REACT_APP_ORB_ARBITRUM_CONTRACT_ADDRESS || ''
export const MOONRIVER_BRIDGE_CONTRACT_ADDRESS =
  process.env.REACT_APP_MOONRIVER_BRIDGE_CONTRACT_ADDRESS || ''
export const MOONBEAM_BRIDGE_CONTRACT_ADDRESS =
  process.env.REACT_APP_MOONBEAM_BRIDGE_CONTRACT_ADDRESS || ''
export const ZKSYNC_BRIDGE_CONTRACT_ADDRESS =
  process.env.REACT_APP_ZKSYNC_BRIDGE_CONTRACT_ADDRESS || ''
export const ARBITRUM_BRIDGE_CONTRACT_ADDRESS =
  process.env.REACT_APP_ARBITRUM_BRIDGE_CONTRACT_ADDRESS || ''
export const MOONRIVER_CHAIN_ID_HEX =
  process.env.REACT_APP_MOONRIVER_CHAIN_ID || ''
export const MOONBEAM_CHAIN_ID_HEX =
  process.env.REACT_APP_MOONBEAM_CHAIN_ID || ''
export const ZKSYNC_CHAIN_ID_HEX = process.env.REACT_APP_ZKSYNC_CHAIN_ID || ''
export const ARBITRUM_CHAIN_ID_HEX =
  process.env.REACT_APP_ARBITRUM_CHAIN_ID || ''
export const MOONRIVER_CHAIN_ID =
  process.env.REACT_APP_MOONRIVER_CHAIN_ID_NUMBER || ''
export const MOONBEAM_CHAIN_ID =
  process.env.REACT_APP_MOONBEAM_CHAIN_ID_NUMBER || ''
export const ZKSYNC_CHAIN_ID =
  process.env.REACT_APP_ZKSYNC_CHAIN_ID_NUMBER || ''
export const ARBITRUM_CHAIN_ID =
  process.env.REACT_APP_ARBITRUM_CHAIN_ID_NUMBER || ''
export const MOONRIVER_EXPLORER_URL = process.env.REACT_APP_MOONRIVER_EXPLORER
export const MOONBEAM_EXPLORER_URL = process.env.REACT_APP_MOONBEAM_EXPLORER
export const ARBITRUM_EXPLORER_URL = process.env.REACT_APP_ARBITRUM_EXPLORER
export const ZKSYNC_EXPLORER_URL = process.env.REACT_APP_ZKSYNC_EXPLORER
export const MOONRIVER_RPC_URL = process.env.REACT_APP_MOONRIVER_RPC
export const MOONBEAM_RPC_URL = process.env.REACT_APP_MOONBEAM_RPC
export const ARBITRUM_RPC_URL = process.env.REACT_APP_ARBITRUM_RPC
export const ZKSYNC_RPC_URL = process.env.REACT_APP_ZKSYNC_RPC

export const LANDING_LINK =
  process.env.REACT_APP_BRIDGE_LANDING_LINK || 'https://demo.orionbridge.xyz'

export const NETWORK_CHAINS = {
  moonriver: MOONRIVER_CHAIN_ID,
  moonbeam: MOONBEAM_CHAIN_ID,
  'arbitrum goerli': ARBITRUM_CHAIN_ID,
  'zksync era': ZKSYNC_CHAIN_ID,
}
